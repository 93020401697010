<script setup lang="ts">
const props = defineProps(['copyText']);

const showCopyButton = ref(false);
const showCopiedTooltip = ref(false);
const copyToClipboard = () => {
  navigator.clipboard.writeText(props.copyText);
  showCopiedTooltip.value = true;
  setTimeout(() => {
    showCopiedTooltip.value = false;
  }, 2000);
};
</script>

<template>
  <p
    class="d-flex align-center pa-0 ma-0 clipboard-component"
    data-id="clipboard-component"
    @mouseover="showCopyButton=true"
    @mouseleave="showCopyButton=false"
  >
    <slot />
    <v-btn
      v-if="copyText"
      variant="plain"
      icon="mdi mdi-content-copy"
      size="x-small"
      v-bind="props"
      :class="`copy-icon-button show-copy-button-${showCopyButton}`"
      @click="copyToClipboard"
    />
  </p>
</template>

<style lang="scss">
.clipboard-component {
  .copy-icon-button {
    visibility: hidden;
    height: 20px !important;
    width: 20px !important;

    &.show-copy-button-true {
      visibility: visible;
    }
  }
}
</style>
